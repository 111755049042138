import { useCallback, useMemo } from "react";
import { styled } from "@/stitches.config";
import { useAppDispatch } from "@/hooks";
import { IWindow, setCurrentExplorer } from "@/store/slices/windows.slice";
import { explorerItemsList } from "@/data/explorer.items.list";
import { Button, Div } from "@/components/base.styled";

export const FolderWrapper = styled("div", {
  fontSize: "$base",
});

export const MenuButton = styled(Button, {
  backgroundColor: "transparent",
  padding: "$sm",
  fontSize: "$sm",
});

interface FolderMenuProps {
  activeWindow: IWindow;
}

export const FolderMenu: React.FC<FolderMenuProps> = ({ activeWindow }) => {
  const dispatch = useAppDispatch();

  const explorerItem = useMemo(() => {
    return explorerItemsList.getItemById(activeWindow.currentExplorer)!;
  }, [activeWindow.currentExplorer]);

  const setCurrentExplorerHandler = useCallback(() => {
    if (explorerItem.parent != null) {
      dispatch(
        setCurrentExplorer({
          parentWindowId: activeWindow.id,
          current: explorerItem.parent,
        })
      );
    }
  }, [activeWindow.id, explorerItem.parent, dispatch]);

  return (
    <Div>
      <MenuButton onClick={setCurrentExplorerHandler}>Zurück</MenuButton>
      <MenuButton>Bearbeiten</MenuButton>
      <MenuButton>Anzeigen</MenuButton>
      <MenuButton css={{ "@bp_400": { display: "none" } }}>Hilfe</MenuButton>
    </Div>
  );
};

export const FolderContentWrapper = styled("div", {
  boxShadow: "$shadow_2",
  backgroundColor: "$mono_white",
  padding: "0.5rem",
});

export const FolderContentInner = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  alignContent: "flex-start",
  rowGap: "$sm",
  flexWrap: "wrap",
  padding: "0.5rem",
  overflowY: "auto",
  minHeight: "30rem",
  maxHeight: "60vh",
});

export const FolderInfoBox = styled("div", {
  marginTop: "0.4rem",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",

  "& > *": {
    boxShadow: "$shadow_1",
    padding: "0.6rem",
  },
});






// stuff for sync modal

export const ModalWrapper = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1000,
});

export const ModalContent = styled('div', {
  backgroundColor: '$mono_white',
  padding: '1rem',
  borderRadius: '3px',
  boxShadow: '$shadow_2',
  width: '300px',
  textAlign: 'center',
});

export const ModalHeader = styled('div', {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  position: 'relative',
  padding: '0.5rem',
  borderBottom: '1px solid $mono_300',
});

export const CloseButton = styled('button', {
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  fontSize: '1.2rem',
  padding: '0.5rem',
});

export const ModalBody = styled('div', {
  padding: '0.5rem',
});

export const ModalTitleBar = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: '$mono_200',
    borderBottom: '1px solid $mono_200',
});

export const ModalTitle = styled('span', {
  fontWeight: 'bold',
});

