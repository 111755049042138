import type * as Stitches from "@stitches/react";
import { createStitches } from "@stitches/react";

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      mono_white: "#FFFFFF",
      mono_black: "#000000",
      mono_50: "#E3E3E3",
      mono_100: "#C3C3C3",
      mono_200: "#A0A0A0",
      mono_300: "#8E8E8E",
      blue_50: "#001CF5",
      blue_100: "#000EA3",
      blue_200: "#02007F",
      green_100: "#26B50F",
      green_200: "#008282",
      red_100: "#EB3323",
      red_200: "#9C2054",
      body_bg: "$green_200",
    },
    shadows: {
      win_95:
        "inset -2px -2px 0px #262626, inset 2px 2px 0px #f0f0f0, inset -4px -4px 0px #7e7e7e",
      shadow_1: "inset -2px -2px 0px #F0F0F0, inset 2px 2px 0px #7E7E7E",
      shadow_2:
        "inset 2px 2px 0px #262626, inset -2px -2px 0px #F0F0F0, inset 4px 4px 0px #7E7E7E, inset -4px -4px 0px #B1B1B1;",
    },
    space: {
      sm: "1rem",
      md: "2rem",
      lg: "3rem",
      xl: "4rem",
    },
    sizes: {
      full: "100%",
    },
    fontSizes: {
      xs: "1.2rem",
      sm: "1.4rem",
      base: "1.6rem",
      label: "$sm",
    },
  },
  utils: {
    size: (value: Stitches.PropertyValue<"width">) => ({
      width: value,
      height: value,
    }),
  },
  media: {
    bp_400: "(max-width: 400px)",
    bp_520: "(max-width: 520px)",
    bp_620: "(max-width: 620px)",
  },
});

export const globalStyles = globalCss({
  "@font-face": [
    {
      fontFamily: "MS Sans Serif",
      src: 'url("./assets/fonts/MS-Sans-Serif.ttf") format("truetype")',
    },
    {
      fontFamily: "W95FA",
      src: 'url("./assets/fonts/W95FA.otf")',
    },
    {
      fontFamily: "GochiHand",
      src: 'url("./assets/fonts/gochi-hand.regular.ttf")',
    },
  ],
  "*, *:before, *:after": {
    boxSizing: "inherit",
    margin: 0,
    padding: 0,
    fontFamily: "inherit",
    letterSpacing: "inherit",
  },
  html: {
    boxSizing: "border-box",
    fontSize: "62.5%",
    fontFamily: "W95FA, MS Sans Serif, Courier, monospace",
    letterSpacing: "0.2rem",
  },
  body: {
    backgroundColor: "$body_bg",
  },
  ".font-gochi": {
    fontFamily: "GochiHand",
  },
});
