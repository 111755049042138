import { useCallback, useState } from "react";
import { SignIn } from "@/firebase/auth";
import { Div } from "@/components/base.styled";
import { Computer } from "@/components/icons";
import { TitleBar, TitleBarText } from "@/components/title.bar";
import ErrorDialog from "@/components/error.dialog";
import { LoadingState } from "@/models/common";
import hourglass from "@/components/icons/hourglass.gif";
import * as St from "./styles";

const Login = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [credentialsError, setCredentialsError] = useState(false);
  const [loading, setLoading] = useState<LoadingState>("idle");

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();
      setCredentialsError(false);
      setLoading("loading");

      // Check if credentials are not empty
      if (credentials.username === "" || credentials.password === "") {
        setCredentialsError(true);
        setLoading("failed");
        return;
      }

      try {
        await SignIn(credentials.username, credentials.password);
        setLoading("succeeded");
      } catch (error: any) {
        setCredentialsError(true);
        setLoading("failed");
      }
    },
    [credentials]
  );

  return (
    <St.Login>
      <St.LoginDialog>
        <TitleBar>
          <TitleBarText color={"white"} textCentered>
            Gretenburg Polizei Login
          </TitleBarText>
        </TitleBar>
        <Div
          css={{
            padding: "$lg",
            "@bp_520": {
              padding: "$md",
            },
          }}
        >
          <form onSubmit={handleSubmit}>
            <St.FieldsWrapper>
              <Computer
                css={{
                  size: "12rem",
                  "@bp_520": {
                    size: "8rem",
                  },
                  "@bp_400": {
                    marginBottom: "1.4rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  },
                }}
              />
              <Div css={{ flex: 1 }}>
                <St.FormField>
                  <label>Benutzer</label>
                  <input
                    type="text"
                    value={credentials.username}
                    onChange={(e) =>
                      setCredentials({
                        ...credentials,
                        username: e.target.value.trim(),
                      })
                    }
                  />
                </St.FormField>

                <St.FormField css={{ marginTop: "1.4rem" }}>
                  <label>Passwort</label>
                  <input
                    type="password"
                    value={credentials.password}
                    onChange={(e) =>
                      setCredentials({
                        ...credentials,
                        password: e.target.value.trim(),
                      })
                    }
                  />
                </St.FormField>
              </Div>
            </St.FieldsWrapper>
            <St.SubmitButton type="submit" disabled={loading === "loading"}>
              <span className="text">Anmelden</span>
              <img src={hourglass} alt="hourglass" className="loader" />
            </St.SubmitButton>
          </form>
        </Div>

        {/* Incorrect Credentials Error Dialog */}
        {credentialsError === true && (
          <ErrorDialog
            message={"Falscher Benutzer oder Passwort. Bitte erneut versuchen."}
            closeErrorDialog={() => setCredentialsError(false)}
          />
        )}
      </St.LoginDialog>
    </St.Login>
  );
};

export default Login;
