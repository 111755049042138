import { styled } from "@/stitches.config";
import { Button } from "@/components/base.styled";

export const ErrorDialogWrapper = styled("div", {
  backgroundColor: "$mono_100",
  padding: "0.4rem",
  boxShadow: "$win_95",
  maxWidth: "40rem",
  width: "90%",
  position: "absolute",
  top: "45%",
  left: "45%",
  transform: "translate(-45%, -45%)",
  zIndex: 10,
});

export const DialogBody = styled("div", {
  padding: "$md",
  "@bp_400": {
    padding: "$md $sm",
  },
});

export const DialogBodyContent = styled("div", {
  display: "flex",
  alignItems: "center",
  columnGap: "$md",
});

export const DialogCloseButton = styled(Button, {
  backgroundColor: "$mono_100",
  boxShadow: "$win_95",
  padding: "$sm",
  textAlign: "center",
  fontSize: "$base",
});
