// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_QtIpNBVI6XyZXiIWaM-hUE8yQ1K4OTk",
  authDomain: "cc-police-server.firebaseapp.com",
  projectId: "cc-police-server",
  storageBucket: "cc-police-server.appspot.com",
  messagingSenderId: "224025822465",
  appId: "1:224025822465:web:8cf9aded5aabd6d275c700",
  measurementId: "G-PJR3BY728E",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
