import { styled } from "@/stitches.config";
import { Button } from "@/components/base.styled";

export const ExplorerWrapper = styled(Button, {
  display: "flex",
  alignItems: "center",
  backgroundColor: "transparent",
  fontSize: "$base",

  variants: {
    placement: {
      desktop: {
        flexDirection: "column",
        rowGap: "0.5rem",
        margin: "$md $sm",
        color: "$mono_white",
      },
      start_menu: {
        flexDirection: "row",
        columnGap: "$md",
        padding: "$sm",
        minWidth: "20rem",
      },
      in_window: {
        flexDirection: "column",
        rowGap: "0.5rem",
        margin: "$sm",
        color: "$mono_black",
        maxWidth: "10rem",
        wordBreak: "break-word",
        hyphens: "auto",
      },
    },
  },
});
