import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "@/hooks";
import { selectWindows } from "@/store/slices/windows.slice";
import { selectUser } from "@/store/slices/auth.slice";
import { Div } from "@/components/base.styled";
import Explorer from "@/components/explorer";
import SingleWindow from "@/components/single.window";
import StartMenu from "@/components/start.menu";
import { explorerItemsList } from "@/data/explorer.items.list";
import hourglass from "@/components/icons/hourglass.gif";
import * as St from "./styles";

const Desktop = () => {
  const windows = useAppSelector(selectWindows);
  const user = useAppSelector(selectUser)!;
  const [height, setHeight] = useState<string>(window.innerHeight + "px");

  useEffect(() => {
    const listener = () => {
      setHeight(window.innerHeight + "px");
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  const desktopItems = useMemo(() => {
    // TODO: Remove this after debugging
    console.log("difficulty:" + user.selected_difficulty);
    console.log("episode:" + user.episode);

    return explorerItemsList.getDesktopItems(
      user.selected_difficulty,
      user.episode
    );
  }, [user.selected_difficulty, user.episode]);

  return (
    <St.DesktopWrapper
      css={{
        height,
      }}
    >
      {/* Hack to download resources ahead of use */}
      <div
        className="font-gochi"
        style={{ visibility: "hidden", height: 0, width: 0 }}
      >
        <span>Load Gochi Hand Fonts</span>
        <img src={hourglass} alt="hourglass" />
      </div>

      <Div css={{ flexGrow: 1 }}>
        <St.DesktopItemsWrapper>
          {desktopItems.map((item) => {
            return <Explorer key={item.id} item={item} placement="desktop" />;
          })}
          {windows.map((window) => {
            return <SingleWindow key={window.id} activeWindow={window} />;
          })}
        </St.DesktopItemsWrapper>
      </Div>
      <StartMenu />
    </St.DesktopWrapper>
  );
};

export default Desktop;
