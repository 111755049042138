// SyncingModal.tsx

import React from 'react';
import ModalTitleBar from './ModalTitleBar';
import * as St from './styles';

interface SyncingModalProps {
    timeRemaining: string | null;
    onClose: () => void;
}

const SyncingModal: React.FC<SyncingModalProps> = ({ timeRemaining, onClose }) => {
    if (!timeRemaining) return null;

    return (
        <St.ModalWrapper>
            <St.ModalContent>
                <ModalTitleBar onClose={onClose} title="Synchronisierung läuft..." />
                <St.ModalBody>
                    <p>{timeRemaining} verbleibend</p>
                </St.ModalBody>
            </St.ModalContent>
        </St.ModalWrapper>
    );
};

export default SyncingModal;
