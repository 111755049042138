import { ExplorerItem } from "@/models/explorer.item";

const kfzRegister = new ExplorerItem({
  title: "KFZ Register",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "kfz-register",
  icon: "Shell3216",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "3rem",
    },
  },
});

const dateiablage = new ExplorerItem({
  title: "Dateiablage",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "folder",
  icon: "Shell3221",
  iconProps: {
    variant: "32x32_4",
  },
  iconPropsDesktop: {
    css: {
      size: "3.8rem",
    },
  },
  iconPropsStartMenu: {
    css: {
      size: "3.2rem",
    },
  },
});

const papierkorb = new ExplorerItem({
  title: "Papierkorb",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "folder",
  icon: "RecycleFull",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "3rem",
    },
  },
});

// Children of papierkorb
const bildschirmfoto_1 = new ExplorerItem({
  title: "bildschirmfoto_1",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "image",
  imageUrl: "/resources/images/bildschirmfoto_1.png",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: papierkorb.id,
});

const bildschirmfoto_2 = new ExplorerItem({
  title: "bildschirmfoto_2",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "image",
  imageUrl: "/resources/images/bildschirmfoto_2.png",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: papierkorb.id,
});


// Children of dateiablage
const fall_2345xgrf = new ExplorerItem({
  title: "Fall 2345XGRF",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "folder",
  icon: "Shell3221",
  iconProps: {
    variant: "32x32_4",
  },
  parent: dateiablage.id,
});

const fall_5645hg7f = new ExplorerItem({
  title: "Fall 5645HG7F",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "folder",
  icon: "Shell3221",
  iconProps: {
    variant: "32x32_4",
  },
  iconPropsDesktop: {
    css: {
      size: "3.8rem",
    },
  },
  iconPropsStartMenu: {
    css: {
      size: "3.2rem",
    },
  },
  parent: dateiablage.id,
});

const fall_2685re7c = new ExplorerItem({
  title: "Fall 2685RE7C",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "folder",
  icon: "Shell3221",
  iconProps: {
    variant: "32x32_4",
  },
  iconPropsDesktop: {
    css: {
      size: "3.8rem",
    },
  },
  iconPropsStartMenu: {
    css: {
      size: "3.2rem",
    },
  },
  parent: dateiablage.id,
});

// Children of fall_2345xgrf
const verhoer_rossi = new ExplorerItem({
  title: "Verhör Rossi",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "audio",
  audioUrl: "/resources/audios/verhoer-rossi.mp3",
  icon: "MediaCd",
  iconProps: {
    variant: "32x32_4",
  },
  parent: fall_2345xgrf.id,
});

const mailbox_rossi = new ExplorerItem({
  title: "Mailbox Rossi",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "audio",
  audioUrl: "/resources/audios/mailbox-rossi-telefonmitschnitt.mp3",
  icon: "MediaCd",
  iconProps: {
    variant: "32x32_4",
  },
  parent: fall_2345xgrf.id,
});

const hausdurchsuchung_steinacker = new ExplorerItem({
  title: "Hausdurchsuchung Steinacker",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "folder",
  icon: "Shell3221",
  iconProps: {
    variant: "32x32_4",
  },
  parent: fall_2345xgrf.id,
});

const ausweis_tatort = new ExplorerItem({
  title: "Ausweis Tatort",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "image",
  imageUrl: "/resources/images/ausweis_tatort.png",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: fall_2345xgrf.id,
});

// Children of fall_5645hg7f
const verhoer_steinacker = new ExplorerItem({
  title: "Verhör Steinacker",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "audio",
  audioUrl: "/resources/audios/verhoer-ursula-steinacker.mp3",
  icon: "MediaCd",
  iconProps: {
    variant: "32x32_4",
  },
  parent: fall_5645hg7f.id,
});

// children of hausdurchsuchung_steinacker
const img_4944 = new ExplorerItem({
  title: "IMG_4944",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "image",
  imageUrl: "/resources/images/img_4944.jpeg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: hausdurchsuchung_steinacker.id,
});

const img_4951 = new ExplorerItem({
  title: "IMG_4951",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "image",
  imageUrl: "/resources/images/img_4951.jpeg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: hausdurchsuchung_steinacker.id,
});

const img_4952 = new ExplorerItem({
  title: "IMG_4952",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "image",
  imageUrl: "/resources/images/img_4952.jpeg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: hausdurchsuchung_steinacker.id,
});


const img_4955 = new ExplorerItem({
  title: "IMG_4955",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "image",
  imageUrl: "/resources/images/img_4955.jpeg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: hausdurchsuchung_steinacker.id,
});

// Children of fall_2685re7c
const schreibtisch_rossi = new ExplorerItem({
  title: "Schreibtisch Rossi",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "002",
  type: "image",
  imageUrl: "/resources/images/schreibtisch_rossi.jpeg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: fall_2685re7c.id,
});

export const episode_002 = [
  kfzRegister,
  dateiablage,
  papierkorb,
  bildschirmfoto_1,
  bildschirmfoto_2,
  fall_2345xgrf,
  fall_5645hg7f,
  fall_2685re7c,
  verhoer_rossi,
  mailbox_rossi,
  hausdurchsuchung_steinacker,
  ausweis_tatort,
  verhoer_steinacker,
  img_4944,
  img_4951,
  img_4952,
  img_4955,
  schreibtisch_rossi,
];
