import React, { useEffect, useRef } from "react";
import { useAppSelector } from "@/hooks";
import { selectUser } from "@/store/slices/auth.slice";
import { css } from "@/stitches.config";
import { ExplorerItem } from "@/models/explorer.item";
import hourglass from "@/components/icons/hourglass.gif";

interface ImageViewerProps {
  explorerItem: ExplorerItem;
}

const imgClass = css({
  maxWidth: "100%",
});

const loadingClass = css({
  width: "2rem",
  margin: "4rem auto",
  display: "block",
});

const ImageViewer: React.FC<ImageViewerProps> = ({ explorerItem }) => {
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (
      imgRef.current !== null &&
      explorerItem.imageUrl !== "/resources/images/schreibtisch_rossi.jpeg"
    ) {
      const imgElement = imgRef.current;

      const realImg = new Image();
      realImg.src = explorerItem.imageUrl!;

      realImg.onload = () => {
        imgElement.classList.remove(loadingClass());
        imgElement.src = realImg.src;
      };
    }
  }, [imgRef, explorerItem.imageUrl]);

  if (explorerItem.imageUrl === "/resources/images/schreibtisch_rossi.jpeg") {
    return <PasswordImageViewer explorerItem={explorerItem} />;
  }

  // Regular Images
  return (
    <img
      ref={imgRef}
      src={hourglass}
      alt={explorerItem.title}
      className={`${imgClass()} ${loadingClass()}`}
    />
  );
};

export default ImageViewer;

const PasswordImageViewer: React.FC<ImageViewerProps> = ({ explorerItem }) => {
  const imgRef = useRef<HTMLImageElement>(null);

  // Dynamic Image for Password
  const user = useAppSelector(selectUser)!;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current !== null) {
      const canvasElement = canvasRef.current;
      const context = canvasElement.getContext("2d")!;

      const img = new Image();
      img.src = explorerItem.imageUrl!;

      img.onload = function () {
        if (imgRef.current !== null) {
          imgRef.current.style.display = "none";
        }

        canvasElement.width = img.naturalWidth;
        canvasElement.height = img.naturalHeight;

        context.save();
        context.drawImage(img, 0, 0);
        context.restore();

        context.save();
        context.translate(
          canvasElement.width - 152,
          canvasElement.height - 822
        );
        context.rotate(-19 * (Math.PI / 180));
        context.fillStyle = "black";
        context.font = `30px GochiHand`;
        context.fillText(user.email_rossi_login_string.toString(), 0, 0);
        context.restore();
      };
    }
    return () => {};
  }, [canvasRef, imgRef, explorerItem.imageUrl, user.email_rossi_login_string]);

  return (
    <>
      <canvas
        ref={canvasRef}
        className={imgClass()}
        height="0"
        width="0"
      ></canvas>
      <img
        ref={imgRef}
        src={hourglass}
        alt={explorerItem.title}
        className={`${imgClass()} ${loadingClass()}`}
      />
    </>
  );
};
