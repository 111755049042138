import { Button } from "@/components/base.styled";
import Icons from "@/components/icons";
import { styled } from "@/stitches.config";
import type * as Stitches from "@stitches/react";

const ActionButton = styled(Button, {
  lineHeight: 0,
  display: "inline-block",

  "& + &": {
    marginLeft: "0.4rem",
  },
});

interface ActionButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  css?: Stitches.CSS;
  iconCSS?: Stitches.CSS;
}

export const CloseButton: React.FC<ActionButtonProps> = ({
  iconCSS = {},
  ...props
}) => (
  <ActionButton {...props}>
    <Icons.Close css={{ size: "1.7rem", ...iconCSS }} />
  </ActionButton>
);

export const MinimizeButton: React.FC<ActionButtonProps> = ({
  iconCSS = {},
  ...props
}) => (
  <ActionButton {...props}>
    <Icons.Minimize css={{ size: "1.7rem", ...iconCSS }} />
  </ActionButton>
);

export const MaximizeButton: React.FC<ActionButtonProps> = ({
  iconCSS = {},
  ...props
}) => (
  <ActionButton {...props}>
    <Icons.Maximize css={{ size: "1.7rem", ...iconCSS }} />
  </ActionButton>
);

export const SubmitButtonWithLoading = styled(Button, {
  height: "3.8rem",
  padding: "$sm",
  boxShadow: "$win_95",
  backgroundColor: "$mono_100",
  fontSize: "$base",
  textAlign: "center",

  "& .text": {
    display: "block",
  },

  "& .loader": {
    display: "none",
    size: "1.8rem",
  },

  "&:disabled": {
    "& .text": {
      display: "none",
    },
    "& .loader": {
      display: "inline-block",
    },
  },
});
