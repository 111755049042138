import * as Icons from "./icons";
export * from "./icons";

export type IconsName = keyof typeof Icons;

export function getIconFC(name: IconsName) {
  return Icons[name] as React.FC<Icons.StyledIconProps>;
}

export default Icons;
