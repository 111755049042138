import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  documentId,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from ".";
import { Faker, de } from "@faker-js/faker";
import { WithID } from "@/models/common";

// Set faker js locale for Germany
const faker = new Faker({ locale: [de] });

export interface KFZRecord {
  name: string;
  address: string;
  licenseNumber: string;
  image: string;
  timestamp: Timestamp;
}

type Gender = "male" | "female";

export interface KFZImage {
  src: string;
  gender: Gender;
  isUsed: boolean;
}

const getImageForGender = async (gender: Gender): Promise<KFZImage> => {
  const kfzImagesRef = collection(
    db,
    "kfz-register-images"
  ) as CollectionReference<KFZImage>;
  const q = query(
    kfzImagesRef,
    where("gender", "==", gender),
    where("isUsed", "==", false),
    limit(1)
  );

  let snapshot = await getDocs(q);

  if (snapshot.empty) {
    const randomId = doc(kfzImagesRef).id;

    const q = query(
      kfzImagesRef,
      where("gender", "==", gender),
      where(documentId(), ">", randomId),
      limit(1)
    );
    snapshot = await getDocs(q);

    if (snapshot.empty) {
      const q = query(
        kfzImagesRef,
        where("gender", "==", gender),
        where(documentId(), "<", randomId),
        limit(1)
      );
      snapshot = await getDocs(q);
    }
  }

  return snapshot.docs[0].data();
};

export const searchKFZRegister = async (code: string, number: string) => {
  const kfzRef = collection(
    db,
    "kfz-register"
  ) as CollectionReference<KFZRecord>;
  const q = query(kfzRef, where("licenseNumber", "==", `${code}-${number}`));

  const snapshot = await getDocs(q);

  let record: WithID<KFZRecord>;

  if (snapshot.empty) {
    // Get a random gender
    const gender: Gender = Math.random() < 0.5 ? "male" : "female";

    // Get image for gender
    const image = await getImageForGender(gender);

    const docRef = await addDoc(kfzRef, {
      licenseNumber: `${code}-${number}`,
      name: faker.name.fullName({ sex: gender }).toUpperCase(),
      address: faker.address.streetAddress().toUpperCase(),
      image: image.src,
      timestamp: serverTimestamp(),
    });

    const savedNewRecord = await getDoc(docRef);
    record = {
      id: docRef.id,
      ...savedNewRecord.data()!,
      timestamp: savedNewRecord.data()!.timestamp as Timestamp,
    };
  } else {
    record = {
      id: snapshot.docs[0].id,
      ...snapshot.docs[0].data(),
    };
  }

  return record;
};
