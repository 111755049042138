import React from "react";
import { useOnClickOutside } from "usehooks-ts";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Div } from "@/components/base.styled";
import TaskbarTile from "@/components/taskbar.tile";
import { useAppSelector } from "@/hooks";
import { selectWindows } from "@/store/slices/windows.slice";
import * as St from "./styles";

const StartMenu = () => {
  const windows = useAppSelector(selectWindows);
  const [isOpen, setIsOpen] = React.useState(false);
  const menuOptionsRef = React.useRef<HTMLDivElement>(null);
  const startButtonRef = React.useRef<HTMLButtonElement>(null);

  // Close the start menu options when the user clicks outside of it
  useOnClickOutside(menuOptionsRef, (e) => {
    // Start button is clicked then do nothing
    if (!startButtonRef.current?.contains(e.target as Node)) {
      setIsOpen(false);
    }
  });

  return (
    <St.StartMenuWrapper>
      {isOpen && (
        <St.StartMenuOptions
          closeStartMenuHandler={() => setIsOpen(false)}
          ref={menuOptionsRef}
        />
      )}
      <St.StartMenuInner>
        <St.StartButton
          ref={startButtonRef}
          onClick={() => setIsOpen((prev) => !prev)}
          css={{
            backgroundColor: !isOpen ? "$mono_100" : "$mono_white",
            boxShadow: !isOpen ? "$win_95" : "$shadow_2",
          }}
        />
        <Div css={{ flexGrow: 1 }}>
          <Scrollbars autoHide>
            <St.TaskbarTilesWrapper>
              {windows.map((window) => {
                return <TaskbarTile key={window.id} activeWindow={window} />;
              })}
            </St.TaskbarTilesWrapper>
          </Scrollbars>
        </Div>
        <St.TimeBox />
      </St.StartMenuInner>
    </St.StartMenuWrapper>
  );
};

export default StartMenu;
