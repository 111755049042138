import React from 'react';
import { CloseButton } from "@/components/buttons";
import * as St from './styles';

interface ModalTitleBarProps {
    onClose: () => void;
    title?: string; // Optional title prop
}

const ModalTitleBar: React.FC<ModalTitleBarProps> = ({ onClose, title }) => {
    return (
        <St.ModalTitleBar>
            <St.ModalTitle>{title || ""}</St.ModalTitle>
            <CloseButton onClick={onClose} />
        </St.ModalTitleBar>
    );
};

export default ModalTitleBar;
