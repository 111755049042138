import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { styled } from "@/stitches.config";
import { useAppSelector } from "@/hooks";
import { selectUser } from "@/store/slices/auth.slice";
import { Button } from "@/components/base.styled";
import { Logo, Systray220 } from "@/components/icons";
import { Div } from "@/components/base.styled";
import Explorer from "@/components/explorer";
import { explorerItemsList } from "@/data/explorer.items.list";
import { ExplorerItem } from "@/models/explorer.item";

export const StartMenuWrapper = styled("div", {
  position: "relative",
  backgroundColor: "$mono_100",
});

export const StartMenuInner = styled("div", {
  display: "flex",
  columnGap: "1rem",
  padding: "0.2rem 0",
  "@bp_520": {
    marginBottom: "$sm",
  },
});

export const TaskbarTilesWrapper = styled("div", {
  height: "100%",
  width: "100%",
  display: "flex",
  columnGap: "1rem",
});

const StyledStartButton = styled(Button, {
  display: "flex",
  alignItems: "center",
  columnGap: "$sm",
  textAlign: "center",
  boxShadow: "$win_95",
  fontSize: "$base",
  backgroundColor: "$mono_100",
  padding: "0.6rem $sm",

  "@bp_520": {
    padding: "$sm",
  },
});

interface StartButtonProps
  extends React.ComponentProps<typeof StyledStartButton> {}

export const StartButton = React.forwardRef<
  HTMLButtonElement,
  StartButtonProps
>((props, ref) => {
  return (
    <StyledStartButton {...props} ref={ref}>
      <Logo variant="32x32_4" css={{ size: "2rem" }} />
      <span>Start</span>
    </StyledStartButton>
  );
});

interface StartMenuOptionsProps {
  closeStartMenuHandler: () => void;
}

export const StartMenuOptions = React.forwardRef<
  HTMLDivElement,
  StartMenuOptionsProps
>(({ closeStartMenuHandler }, ref) => {
  const user = useAppSelector(selectUser)!;

  const startMenuItems = useMemo(() => {
    return explorerItemsList.getStartMenuItems(
      user.selected_difficulty,
      user.episode
    );
  }, [user.selected_difficulty, user.episode]);

  return (
    <Div
      css={{
        boxShadow: "$win_95",
        backgroundColor: "$mono_100",
        padding: "0.4rem",
        position: "absolute",
        transform: "translateY(-100%)",
        zIndex: 5000,
      }}
      ref={ref}
    >
      {startMenuItems.map((item) => {
        return (
          <Div onClick={closeStartMenuHandler} key={item.id}>
            <Explorer item={item} placement="start_menu" />
          </Div>
        );
      })}
      <Explorer
        item={
          new ExplorerItem({
            title: "Abmelden",
            selected_difficulty: ["easy", "medium", "hard"],
            episode: "abmelden", //TODO: check this??
            type: "no_window",
            icon: "Shell3228",
            iconPropsDesktop: {
              variant: "32x32_4",
              css: { size: "3.5rem" },
            },
            iconPropsStartMenu: {
              variant: "32x32_4",
              css: { size: "3.5rem" },
            },
          })
        }
        placement="start_menu"
      />
    </Div>
  );
});

// TimeBox
export const TimeBox: React.FC = () => {
  const [time, setTime] = useState(moment(new Date()).format("hh:mm A"));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment(new Date()).format("hh:mm A"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Div
      css={{
        display: "flex",
        alignItems: "center",
        columnGap: "$sm",
        boxShadow: "$shadow_1",
        padding: "0.6rem $sm",
        fontSize: "$base",
      }}
    >
      <Systray220 variant="16x16_4" css={{ size: "2rem" }} />
      <span>{time}</span>
    </Div>
  );
};
