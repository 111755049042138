import { styled } from "@/stitches.config";
import { SubmitButtonWithLoading } from "@/components/buttons";

export const Login = styled("div", {
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 $sm",
});

export const LoginDialog = styled("div", {
  backgroundColor: "$mono_100",
  padding: "0.4rem",
  boxShadow: "$win_95",
  maxWidth: "56rem",
  width: "$full",
  position: "relative",
});

export const SubmitButton = styled(SubmitButtonWithLoading, {
  width: "$full",
  marginTop: "$md",
});

export const FieldsWrapper = styled("div", {
  display: "flex",
  columnGap: "$lg",
  alignItems: "center",

  "@bp_520": {
    columnGap: "$md",
  },

  "@bp_400": {
    display: "block",
  },
});

export const FormField = styled("div", {
  label: {
    display: "block",
    fontSize: "$label",
    marginBottom: "0.5rem",
  },

  input: {
    boxShadow: "$win_95",
    fontSize: "$base",
    border: "none",
    padding: "$sm",
    display: "block",
    width: "$full",
    "&:focus": {
      outline: "none",
    },
  },
});
