import {
  arrayUnion,
  collection,
  CollectionReference,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { db } from ".";
import { setLogs, UserLogs } from "@/store/slices/logs.slice";
import { store } from "@/store";

export const setListernerForLogs = (uid: string) => {
  const logsRef = collection(db, "logs") as CollectionReference<UserLogs>;
  const userLogDocRef = doc(logsRef, uid);
  return onSnapshot(userLogDocRef, (snap) => {
    // Update the state
    store.dispatch(
      setLogs({
        id: snap.id,
        ...snap.data(),
      } as UserLogs)
    );
  });
};

export const saveLogOnFirstLogin = async (uid: string) => {
  const logsRef = collection(db, "logs") as CollectionReference<UserLogs>;
  const userLogDocRef = doc(logsRef, uid);

  const userLogsSnapshot = await getDoc(userLogDocRef);

  if (!userLogsSnapshot.exists()) {
    await setDoc(
      userLogDocRef,
      {
        first_logged_in: Date.now(),
      },
      { merge: true }
    );
    return;
  }

  const userLogsData = userLogsSnapshot.data();
  // Check if First Login Timestamp already exists
  const first_logged_in = userLogsData.first_logged_in;
  if (first_logged_in != null) {
    return;
  }

  await setDoc(
    userLogDocRef,
    {
      first_logged_in: Date.now(),
    },
    { merge: true }
  );
};

export const setOpenedPathLog = async (path: string) => {
  // Check if user is logged in
  const user = store.getState().auth.user;
  if (!user) {
    return;
  }

  const opened_path_logs = store.getState().logs.opened_path;

  // check if path is already in the array
  const pathExists = opened_path_logs.some((log) => log.path === path);
  if (pathExists) {
    return;
  }

  // Update opened_path array in db
  const logsRef = collection(db, "logs");
  const userLogDocRef = doc(logsRef, user.id);
  await setDoc(
    userLogDocRef,
    {
      opened_path: arrayUnion({
        path,
        timestamp: Date.now(),
      }),
    },
    { merge: true }
  );
};

export const setLicenseNumberLog = async (license_number: string) => {
  // Check if user is logged in
  const user = store.getState().auth.user;
  if (!user) {
    return;
  }

  const entered_license_numbers = store.getState().logs.entered_license_numbers;

  // check if License Number is already in the array
  const licenseNumberExists = entered_license_numbers.some(
    (log) => log.license_number === license_number
  );
  if (licenseNumberExists) {
    return;
  }

  // Update  array in db
  const logsRef = collection(db, "logs");
  const userLogDocRef = doc(logsRef, user.id);
  await setDoc(
    userLogDocRef,
    {
      entered_license_numbers: arrayUnion({
        license_number,
        timestamp: Date.now(),
      }),
    },
    { merge: true }
  );
};
