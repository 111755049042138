import { styled } from "@/stitches.config";

export const TitleBar = styled("div", {
  display: "flex",
  alignItems: "center",
  backgroundColor: "$blue_200",
  padding: "0.8rem",
  userSelect: "none",
});

export const TitleBarButtons = styled("div", {
  flexShrink: 0,
  display: "flex",
  alignItems: "center",
});

export const TitleBarText = styled("div", {
  flexGrow: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  marginRight: "$sm",

  variants: {
    textCentered: {
      true: {
        textAlign: "center",
      },
    },

    size: {
      sm: {
        fontSize: "$sm",
      },
      base: {
        fontSize: "$base",
      },
    },

    color: {
      white: {
        color: "$mono_white",
      },
      black: {
        color: "$mono_black",
      },
    },
  },

  defaultVariants: {
    size: "base",
    color: "black",
  },
});
