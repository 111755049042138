import React, { useState } from 'react'
import * as St from './styles'
import { IWindow } from '@/store/slices/windows.slice';
import styles from './styles.module.css'

import face1 from './assets/face1.png'
import face2 from './assets/face2.png'
import face3 from './assets/face3.png'
import face4 from './assets/face4.png'

import hair1 from './assets/hair1.png'
import hair2 from './assets/hair2.png'
import hair3 from './assets/hair3.png'
import hair4 from './assets/hair4.png'

import eye1 from './assets/eyes1.png'
import eye2 from './assets/eyes2.png'
import eye3 from './assets/eyes3.png'
import eye4 from './assets/eyes4.png'

import nose1 from './assets/nose1.png'
import nose2 from './assets/nose2.png'
import nose3 from './assets/nose3.png'
import nose4 from './assets/nose4.png'

import eyebrow1 from './assets/eyebrows1.png'
import eyebrow2 from './assets/eyebrows2.png'
import eyebrow3 from './assets/eyebrows3.png'
import eyebrow4 from './assets/eyebrows4.png'

import ear1 from './assets/ears1.png'
import ear2 from './assets/ears2.png'
import ear3 from './assets/ears3.png'
import ear4 from './assets/ears4.png'

import mouth1 from './assets/mouth1.png'
import mouth2 from './assets/mouth2.png'
import mouth3 from './assets/mouth3.png'
import mouth4 from './assets/mouth4.png'


interface ImageGenarationToolProps {
  activeWindow: IWindow;
}
type FaceParts = 'face' | 'eye' | 'hair' | 'nose' | 'eyebrow' | 'ear' | 'mouth'
type FaceGenerationDataProps = {
  [key in FaceParts]?: Array<string>
}

const ImageGenarationTool:React.FC<ImageGenarationToolProps> = ({activeWindow}) => {
  const faceGenerationData:FaceGenerationDataProps = { 
    face: [face1, face2, face3, face4],
    eye: [eye1, eye2, eye3, eye4],
    hair: [hair1, hair2, hair3, hair4],
    nose: [nose1, nose2, nose3, nose4],
    eyebrow: [eyebrow1, eyebrow2, eyebrow3, eyebrow4],
    ear: [ear1, ear2, ear3, ear4],
    mouth: [mouth1, mouth2, mouth3, mouth4]
  }

  const [selectedPart, setSelectedPart] = useState<FaceParts>('face')
  const [selectedFaceIndex, setSelectedFaceIndex] = useState(0)
  const [selectedEyeIndex, setSelectedEyeIndex] = useState(0)
  const [selectedHairIndex, setSelectedHairIndex] = useState(0)
  const [selectedNoseIndex, setSelectedNoseIndex] = useState(0)
  const [selectedEyebrowIndex, setSelectedEyebrowIndex] = useState(0)
  const [selectedEarIndex, setSelectedEarIndex] = useState(0)
  const [selectedMouthIndex, setSelectedMouthIndex] = useState(0)

  const setFacePart = (part:FaceParts, index:number)=>{
    if(part === 'face'){
      setSelectedFaceIndex(index)
    }else if(part === 'eye'){
      setSelectedEyeIndex(index)
    }else if(part === 'hair'){
      setSelectedHairIndex(index)
    }else if(part === 'nose'){
      setSelectedNoseIndex(index)
    }else if(part === "eyebrow"){
      setSelectedEyebrowIndex(index)
    }else if(part === 'ear'){
      setSelectedEarIndex(index)
    }else if(part === 'mouth'){
      setSelectedMouthIndex(index)
    }
  }

  return (
    <St.IGTWrapper>
      {/* Menu */}
      <St.IGTMenu activeWindow={activeWindow}/>
      <St.IGTContentWrapper>
        <St.IGTContentInner>
          <div className={styles.flex}>
            <div className={styles.generated_image_wraper}>
              <img src={faceGenerationData.face?.at(selectedFaceIndex)} alt="Face base" width={"100%"}/>
              <img src={faceGenerationData.hair?.at(selectedHairIndex)} alt="Face base" width={"100%"}/>
              <img src={faceGenerationData.eye?.at(selectedEyeIndex)} alt="Face base" width={"100%"}/>
              <img src={faceGenerationData.nose?.at(selectedNoseIndex)} alt="Face base" width={"100%"}/>
              <img src={faceGenerationData.mouth?.at(selectedMouthIndex)} alt="Face base" width={"100%"}/>
              <img src={faceGenerationData.ear?.at(selectedEarIndex)} alt="Face base" width={"100%"}/>
              <img src={faceGenerationData.eyebrow?.at(selectedEyebrowIndex)} alt="Face base" width={"100%"}/>
            </div>
            <div className={styles.tools_wraper}>
              <div className={styles.face_items}>
                {Object.entries(faceGenerationData).map((entity, index)=>(
                  <div key={index} style={{
                    width: "60px",
                    padding: "5px",
                    border: "1px solid #E0E0E0",
                    borderRadius: "4px",
                    marginBottom: "5px"
                  }} onClick={()=> setSelectedPart(entity[0] as FaceParts)}>
                    {entity[0].length > 0 ? (
                      <img src={entity[1].at(0)} alt="hair" width={'100%'}/>
                    ) : <></>}
                  </div>
                ))}
              </div>
              <div className={styles.face_item_type}>
                {faceGenerationData[selectedPart]?.map((parts, index)=>(
                  <div key={index} style={{
                    width: "60px",
                    padding: "5px",
                    border: "1px solid #E0E0E0",
                    borderRadius: "4px",
                    marginBottom: "5px"
                  }} onClick={() => setFacePart(selectedPart, index)}>
                    <img src={parts} alt={selectedPart} width={'100%'}/>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </St.IGTContentInner>
      </St.IGTContentWrapper>
    </St.IGTWrapper>
  )
}

export default ImageGenarationTool