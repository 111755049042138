import { styled } from "@/stitches.config";

export const KFZRegisterWrapper = styled("div", {
  position: "relative",
  padding: "$md",
  maxHeight: "60vh",
  overflow: "auto",

  "& *": {
    fontSize: "$base",
  },

  "@bp_520": {
    padding: "$md $sm",
  },
});

export const InputsWrapper = styled("div", {
  display: "grid",
  gridTemplateColumns: "4fr 1fr 8fr 3fr",
  columnGap: "$sm",
  alignItems: "center",
  marginTop: "$md",

  "@bp_520": {
    gridTemplateColumns: "5fr 1fr 8fr 3fr",
    columnGap: "0.5rem",
  },
});

export const Input = styled("input", {
  display: "block",
  width: "100%",
  boxShadow: "$shadow_2",
  padding: "$sm",
  border: "none",
  "&:focus": {
    outline: "none",
  },
});

export const Separator = styled("span", {
  fontWeight: "bold",
  textAlign: "center",
});

export const DataWrapper = styled("div", {
  display: "flex",
  marginTop: "$md",
  columnGap: "$md",

  "@bp_520": {
    flexDirection: "column",
    rowGap: "$md",
  },
});

export const ImageWrapper = styled("div", {
  flexShrink: 0,

  "& *": {
    margin: "0 auto",
    size: "20rem",
    display: "block",
  },
});

export const DataTextBox = styled("div", {
  padding: "$sm",
  boxShadow: "$shadow_1",
  marginTop: "$sm",
  minHeight: "3.8rem",
});
