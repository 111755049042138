import React from "react";
import { Div } from "@/components/base.styled";
import { CloseButton } from "@/components/buttons";
import { User4 } from "@/components/icons";
import {
  TitleBar,
  TitleBarButtons,
  TitleBarText,
} from "@/components/title.bar";
import * as St from "./styles";

interface ErrorDialogProps {
  title?: string;
  message: string;
  closeErrorDialog: React.MouseEventHandler<HTMLButtonElement>;
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({
  title = "Fehler",
  message,
  closeErrorDialog,
}) => {
  return (
    <St.ErrorDialogWrapper>
      <TitleBar>
        <TitleBarText color={"white"}>{title}</TitleBarText>
        <TitleBarButtons>
          <CloseButton onClick={closeErrorDialog} />
        </TitleBarButtons>
      </TitleBar>
      <St.DialogBody>
        <St.DialogBodyContent>
          <User4
            variant="32x32_4"
            css={{
              size: "6rem",
              "@bp_400": {
                size: "5rem",
              },
            }}
          />
          <Div css={{ fontSize: "$base" }}>{message}</Div>
        </St.DialogBodyContent>
        <Div css={{ marginTop: "$sm", textAlign: "center" }}>
          <St.DialogCloseButton
            css={{ display: "inline-block" }}
            onClick={closeErrorDialog}
          >
            Zurück
          </St.DialogCloseButton>
        </Div>
      </St.DialogBody>
    </St.ErrorDialogWrapper>
  );
};

export default ErrorDialog;
