import { signInWithCustomToken, signOut } from "firebase/auth";
import { collection, doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { DBUser } from "@/store/slices/auth.slice";
import { auth, db, functions } from ".";

interface SignInResult {
  data: {
    token: string;
  };
}

export const SignIn = async (username: string, password: string) => {
  const signInCF = httpsCallable(functions, "signin");
  const res = (await signInCF({
    username,
    password,
  })) as SignInResult;
  await signInWithCustomToken(auth, res.data.token);
};

export const getUserDoc = async (uid: string) => {
  const usersRef = collection(db, "users");
  const userDocRef = doc(usersRef, uid);
  const snapshot = await getDoc(userDocRef);
  if (snapshot.exists()) {
    return {
      id: snapshot.id,
      ...snapshot.data(),
    } as DBUser;
  } else {
    throw new Error("User not found");
  }
};

export const signOutUser = async () => {
  try {
    await signOut(auth);
  } catch (error) {}
};
