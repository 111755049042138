import { styled } from "@/stitches.config";

export const TileWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  border: "none",
  fontSize: "$base",
  userSelect: "none",
  padding: "0rem $sm",
  maxWidth: "20rem",
  columnGap: "0.5rem",
  "@bp_520": {
    padding: "0rem $md",
  },
});
