import React from "react";
import { KFZRecord, searchKFZRegister } from "@/firebase/kfz.register";
import { setLicenseNumberLog } from "@/firebase/logs";
import { Div } from "@/components/base.styled";
import hourglass from "@/components/icons/hourglass.gif";
import ErrorDialog from "@/components/error.dialog";
import { SubmitButtonWithLoading } from "@/components/buttons";
import { WithID } from "@/models/common";
import * as St from "./styles";

const KFZRegister: React.FC = () => {
  const [licenseNumber, setLicenseNumber] = React.useState({
    code: "",
    number: "",
  });

  const [kfzRecord, setKFZRecord] = React.useState<WithID<KFZRecord> | null>(
    null
  );

  const [error, setError] = React.useState<string | undefined>();

  const submitHanlder: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    const button = e.currentTarget;
    button.disabled = true;
    setError(undefined);

    if (licenseNumber.code === "" || licenseNumber.number === "") {
      button.disabled = false;
      setError("Bitte füllen Sie alle Felder aus.");
      return;
    }

    // Log all license number entries in the db
    setLicenseNumberLog(`${licenseNumber.code}-${licenseNumber.number}`);

    if (licenseNumber.code !== "GB") {
      button.disabled = false;
      setError(
        "Abfrage nur für Gretenburg freigeschaltet. Bitte bei Bedarf den Vorgesetzten ansprechen."
      );
      return;
    }

    try {
      const data = await searchKFZRegister(
        licenseNumber.code,
        licenseNumber.number
      );
      setKFZRecord(data);
      button.disabled = false;
    } catch (error: any) {
      button.disabled = false;
      setKFZRecord(null);
      setError("Etwas ist schief gelaufen");
    }
  };

  return (
    <St.KFZRegisterWrapper>
      <div>
        <p>Geben Sie das zu prüfende KFZ Kennzeichen ein:</p>
        <St.InputsWrapper>
          <St.Input
            type="text"
            value={licenseNumber.code}
            onChange={(e) =>
              setLicenseNumber((prev) => ({
                ...prev,
                code:
                  e.target.value.trim().length > 3
                    ? prev.code
                    : e.target.value.trim().toUpperCase(),
              }))
            }
          />
          <St.Separator>&mdash;</St.Separator>
          <St.Input
            css={{
              flexGrow: 1,
            }}
            type="text"
            value={licenseNumber.number}
            onChange={(e) =>
              setLicenseNumber((prev) => ({
                ...prev,
                number:
                  e.target.value.trim().length > 6
                    ? prev.number
                    : e.target.value.trim().toUpperCase(),
              }))
            }
          />
          <SubmitButtonWithLoading onClick={submitHanlder}>
            <span className="text">Suchen</span>
            <img src={hourglass} alt="hourglass" className="loader" />
          </SubmitButtonWithLoading>
        </St.InputsWrapper>
      </div>

      {/* Divider */}
      <Div
        css={{
          height: "0.2rem",
          backgroundColor: "$mono_white",
          margin: "$md 0",
        }}
      />

      {/* License Plate Number Data */}
      <div>
        <p>Abfrage Ergebnis:</p>

        <St.DataWrapper>
          {/* Left */}
          <St.ImageWrapper>
            {kfzRecord ? (
              <img
                src={kfzRecord.image}
                alt={kfzRecord.name}
                onError={(e) => {
                  if (
                    e.currentTarget.src !==
                    "https://via.placeholder.com/200/FFFFFF?text=Image+Not+Found"
                  ) {
                    e.currentTarget.src =
                      "https://via.placeholder.com/200/FFFFFF?text=Image+Not+Found";
                  }
                }}
              />
            ) : (
              <Div css={{ backgroundColor: "$mono_white" }} />
            )}
          </St.ImageWrapper>

          {/* Right */}
          <Div css={{ flexGrow: 1 }}>
            <div>
              <label>KFZ Halter:</label>
              <St.DataTextBox>{kfzRecord && kfzRecord.name}</St.DataTextBox>
            </div>
            <Div css={{ marginTop: "$md" }}>
              <label>Adresse:</label>
              <St.DataTextBox>{kfzRecord && kfzRecord.address}</St.DataTextBox>
            </Div>
          </Div>
        </St.DataWrapper>
      </div>

      {/* Error Dialog */}
      {error && (
        <ErrorDialog
          message={error}
          closeErrorDialog={() => setError(undefined)}
        />
      )}
    </St.KFZRegisterWrapper>
  );
};

export default KFZRegister;
