import { RootState } from "./../index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Types
interface PathLog {
  path: string;
  timestamp: number;
  episode: string;
}

interface LicenseNumberLog {
  license_number: string;
  timestamp: number;
  episode: string;
}

export interface UserLogs {
  id: string | null;
  first_logged_in: number | null;
  opened_path: PathLog[];
  entered_license_numbers: LicenseNumberLog[];
}

// Initial State
const initialState: UserLogs = {
  id: null,
  first_logged_in: null,
  opened_path: [],
  entered_license_numbers: [],
};

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    setLogs: (state, action: PayloadAction<UserLogs>) => {
      state.id = action.payload.id ?? state.id;
      state.first_logged_in =
        action.payload.first_logged_in ?? state.first_logged_in;
      state.opened_path = action.payload.opened_path ?? state.opened_path;
      state.entered_license_numbers =
        action.payload.entered_license_numbers ?? state.entered_license_numbers;
    },
    resetLogs: (state) => {
      state.id = initialState.id;
      state.first_logged_in = initialState.first_logged_in;
      state.opened_path = initialState.opened_path;
      state.entered_license_numbers = initialState.entered_license_numbers;
    },
  },
});

// Actions
export const { setLogs, resetLogs } = logsSlice.actions;

// Selectors
export const selectLogs = (state: RootState) => state.logs;

// Reducer
export default logsSlice.reducer;
