import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth.slice";
import logsReducer from "./slices/logs.slice";
import windowsReducer from "./slices/windows.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    logs: logsReducer,
    windows: windowsReducer,
  },
});

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Infer the `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
