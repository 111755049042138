import uniqid from "uniqid";
import { createSlice } from "@reduxjs/toolkit";
import { explorerItemsList } from "@/data/explorer.items.list";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/store";

// Types
export interface IWindow {
  id: string;
  rootExplorer: string; // id of ExplorerItem to which this window belongs
  // id of ExplorerItem which is currently opened in this window
  // if currentExplorer === rootExplorer, then this show the items of the rootExplorer
  currentExplorer: string;
  zIndex: number;
  isMinimized: boolean;
  top: number;
  left: number;
}

interface OpenWindow {
  rootExplorer: string;
}

interface WindowsState {
  entities: IWindow[];
  highestZIndex: number;
}

// Initial State
const initialState: WindowsState = {
  entities: [],
  highestZIndex: 1000,
};

export const windowsSlice = createSlice({
  name: "windows",
  initialState,
  reducers: {
    openWindow(state, action: PayloadAction<OpenWindow>) {
      // The following checks are to prevent Recycle Bin to open multiple times and bring it to the top
      // Get the explorer item object
      const explorerItem = explorerItemsList.getItemById(
        action.payload.rootExplorer
      )!;

      // If the item is a recycle bin
      if (explorerItem.title.toLowerCase() === "papierkorb") {
        // Check if there is a recycle bin window open
        const recycleBinWindow = state.entities.find(
          (window) => window.rootExplorer === action.payload.rootExplorer
        );
        // If already open, bring it to the top, else continue to the logic of opening a new window
        if (recycleBinWindow) {
          if (recycleBinWindow.zIndex < state.highestZIndex) {
            state.highestZIndex++;
            recycleBinWindow.zIndex = state.highestZIndex;
          }
          recycleBinWindow.isMinimized = false;
          return;
        }
      }

      // Open a new window
      state.entities.push({
        id: uniqid(),
        rootExplorer: action.payload.rootExplorer,
        currentExplorer: action.payload.rootExplorer,
        zIndex: state.highestZIndex + 1,
        isMinimized: false,
        left: 200 + Math.random() * 100,
        top: 50 + Math.random() * 100,
      });
      state.highestZIndex++;
    },

    closeWindow(state, action: PayloadAction<{ id: string }>) {
      state.entities = state.entities.filter(
        (window) => window.id !== action.payload.id
      );
      if (state.entities.length === 0) {
        state.highestZIndex = initialState.highestZIndex;
      }
    },

    toggleWindow(state, action: PayloadAction<{ id: string }>) {
      const window = state.entities.find(
        (window) => window.id === action.payload.id
      );
      if (window) {
        if (window.isMinimized && window.zIndex < state.highestZIndex) {
          state.highestZIndex++;
          window.zIndex = state.highestZIndex;
          window.isMinimized = false;
          return;
        }

        if (window.isMinimized && window.zIndex === state.highestZIndex) {
          window.isMinimized = false;
          return;
        }

        if (!window.isMinimized && window.zIndex < state.highestZIndex) {
          state.highestZIndex++;
          window.zIndex = state.highestZIndex;
          return;
        }

        if (!window.isMinimized && window.zIndex === state.highestZIndex) {
          window.isMinimized = true;
          return;
        }
      }
    },

    minimizeWindow(state, action: PayloadAction<{ id: string }>) {
      const window = state.entities.find(
        (window) => window.id === action.payload.id
      );
      if (window) {
        window.isMinimized = true;
      }
    },

    setHighestZIndexForWindow(state, action: PayloadAction<{ id: string }>) {
      const window = state.entities.find(
        (window) => window.id === action.payload.id
      );
      if (window && window.zIndex < state.highestZIndex) {
        state.highestZIndex++;
        window.zIndex = state.highestZIndex;
      }
    },

    setCurrentExplorer(
      state,
      action: PayloadAction<{ parentWindowId: string; current: string }>
    ) {
      const parentWindow = state.entities.find(
        (window) => window.id === action.payload.parentWindowId
      );
      if (parentWindow) {
        parentWindow.currentExplorer = action.payload.current;
      }
    },
  },
});

// Action creators
export const {
  openWindow,
  closeWindow,
  setHighestZIndexForWindow,
  toggleWindow,
  minimizeWindow,
  setCurrentExplorer,
} = windowsSlice.actions;

// Selectors
export const selectWindows = (state: RootState) => state.windows.entities;
export const selectHighestIndex = (state: RootState) =>
  state.windows.highestZIndex;

// Reducer
export default windowsSlice.reducer;
