import React, { useState } from 'react';
import ModalTitleBar from './ModalTitleBar'; // Assuming you have this component
import * as St from './styles';

interface PasswordModalProps {
    onClose: () => void;
    onSubmit: (password: string) => void;
}

const PasswordModal: React.FC<PasswordModalProps> = ({ onClose, onSubmit }) => {
    const [password, setPassword] = useState('');

    const handleSubmit = () => {
        onSubmit(password);
        setPassword('');
    };

    return (
        <St.ModalWrapper>
            <St.ModalContent>
                <ModalTitleBar onClose={onClose} title="Datei verschlüsselt" />
                <St.ModalBody>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Passwort eingeben"
                        style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                    />
                    <button onClick={handleSubmit} style={{ width: '100%', padding: '5px' }}>Eingabe</button>
                </St.ModalBody>
            </St.ModalContent>
        </St.ModalWrapper>
    );
};

export default PasswordModal;
