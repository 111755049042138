import { ExplorerItem } from "@/models/explorer.item";


const dateiablage = new ExplorerItem({
  title: "Dateiablage",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "folder",
  icon: "Shell3221",
  iconProps: {
    variant: "32x32_4",
  },
  iconPropsDesktop: {
    css: {
      size: "3.8rem",
    },
  },
  iconPropsStartMenu: {
    css: {
      size: "3.2rem",
    },
  },
});

const papierkorb = new ExplorerItem({
  title: "Papierkorb",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "folder",
  icon: "RecycleFull",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "3rem",
    },
  },
});

const image_generation = new ExplorerItem({
  title: "Phantombild Tool",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image.generation",
  icon: "Computer",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "3rem",
    },
  },
});


// Children of papierkorb
const bildschirmfoto_1 = new ExplorerItem({
  title: "bildschirmfoto_1",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/bildschirmfoto_1.png",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: papierkorb.id,
});

const bildschirmfoto_2 = new ExplorerItem({
  title: "bildschirmfoto_2",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/bildschirmfoto_2.png",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: papierkorb.id,
});


// Children of dateiablage
const fall_traumschiff = new ExplorerItem({
  title: "Fall Traumschiff",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "folder",
  icon: "Shell3221",
  iconProps: {
    variant: "32x32_4",
  },
  parent: dateiablage.id,
});

const safe_closed = new ExplorerItem({
  title: "safe_closed.jpg",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/safe_closed.jpg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: dateiablage.id,
});

const safe = new ExplorerItem({
  title: "Safe Inhalt",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "folder",
  icon: "Shell3221",
  iconProps: {
    variant: "32x32_4",
  },
  parent: dateiablage.id,
  password: "5349",
});


const img_8438 = new ExplorerItem({
  title: "IMG_8438",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/IMG_8438.jpg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: safe.id,
});

const img_8439 = new ExplorerItem({
  title: "IMG_8439",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/IMG_8439.jpg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: safe.id,
});

const img_8440 = new ExplorerItem({
  title: "IMG_8440",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/IMG_8440.jpg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: safe.id,
});

const img_8443 = new ExplorerItem({
  title: "IMG_8443",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/IMG_8443.jpg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: safe.id,
});

const img_8444 = new ExplorerItem({
  title: "IMG_8444",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/IMG_8444.jpg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: safe.id,
});

const safe_open = new ExplorerItem({
  title: "safe_open.jpg",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/safe_open.jpg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: safe.id,
});

const netzlaufwerk = new ExplorerItem({
  title: "Netzlaufwerk",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "folder",
  icon: "Shell3221",
  iconProps: {
    variant: "32x32_4",
  },
  iconPropsDesktop: {
    css: {
      size: "3.8rem",
    },
  },
  iconPropsStartMenu: {
    css: {
      size: "3.2rem",
    },
  },
  delay: 2700, // 45 minutes delay for visibility
});


// Children of fall_traumschiff
const interview_konrad_1 = new ExplorerItem({
  title: "Verhör Konrad 1",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "audio",
  audioUrl: "/resources/audios/ks3-verhör-konrad-1.mp3",
  icon: "MediaCd",
  iconProps: {
    variant: "32x32_4",
  },
  parent: fall_traumschiff.id,
});

const interview_tobias = new ExplorerItem({
  title: "Verhör Tobias",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "audio",
  audioUrl: "/resources/audios/ks3-verhör-tobias.mp3",
  icon: "MediaCd",
  iconProps: {
    variant: "32x32_4",
  },
  parent: fall_traumschiff.id,
});

// children of netzlaufwerk

const interview_konrad_2 = new ExplorerItem({
  title: "Verhör Konrad 2",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "audio",
  audioUrl: "/resources/audios/ks3-verhör-konrad-2.mp3",
  icon: "MediaCd",
  iconProps: {
    variant: "32x32_4",
  },
  parent: netzlaufwerk.id,
});

const interview_captain = new ExplorerItem({
  title: "Verhör Kapitän",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "audio",
  audioUrl: "/resources/audios/ks3-verhör-kapitän.mp3",
  icon: "MediaCd",
  iconProps: {
    variant: "32x32_4",
  },
  parent: netzlaufwerk.id,
});


const autopsy_pdf = new ExplorerItem({ //TODO: Here we should change type to pdf, TODO: 60 min delay
  title: "Relatório de Autópsia",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "pdf",
  imageUrl: "/resources/ks3-autopsy.pdf",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: netzlaufwerk.id,
});



const img_001 = new ExplorerItem({
  title: "img_001",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/ks3-notebook-1.jpeg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: netzlaufwerk.id,
});

const img_002 = new ExplorerItem({
  title: "img_002",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/ks3-notebook-2.jpeg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: netzlaufwerk.id,
});

const img_003 = new ExplorerItem({
  title: "img_003",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/ks3-notebook-3.jpeg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: netzlaufwerk.id,
});

const img_004 = new ExplorerItem({
  title: "img_004",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/ks3-notebook-4.jpeg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: netzlaufwerk.id,
});

const img_005 = new ExplorerItem({
  title: "img_005",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/ks3-notebook-5.jpeg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: netzlaufwerk.id,
});

const img_006 = new ExplorerItem({
  title: "img_006",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/ks3-notebook-6.jpeg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: netzlaufwerk.id,
});

const img_007 = new ExplorerItem({
  title: "img_007",
  selected_difficulty: ["easy", "medium", "hard"],
  episode: "003",
  type: "image",
  imageUrl: "/resources/images/ks3-notebook-7.jpeg",
  icon: "Wangimg128",
  iconProps: {
    variant: "32x32_4",
    css: {
      size: "2.6rem",
    },
  },
  parent: netzlaufwerk.id,
});


export const episode_003 = [
  dateiablage,
  papierkorb,
  safe,
  bildschirmfoto_1,
  bildschirmfoto_2,
  fall_traumschiff,
  netzlaufwerk,
  interview_konrad_1,
  interview_tobias,
  interview_konrad_2,
  interview_captain,
  autopsy_pdf,
  img_001,
  img_002,
  img_003,
  img_004,
  img_005,
  img_006,
  img_007,
  safe_closed,
  img_8438,
  img_8439,
  img_8440,
  img_8443,
  img_8444,
  safe_open,
  image_generation,
];
