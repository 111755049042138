import { css, styled } from "@/stitches.config";

export const SingleWindowWrapper = styled("div", {
  position: "absolute",
  backgroundColor: "$mono_100",
  padding: "0.4rem",
  boxShadow: "$win_95",
  maxWidth: "60rem",
  width: "90vw",
});

export const AudioWindowWrapper = styled("div", {
  position: "absolute",
  backgroundColor: "$mono_100",
});

export const mediaPlayerClass = css({
  maxWidth: "60rem",
  width: "90vw",
});

export const mediaPlayerHeaderClass = css({
  padding: "0.8rem !important",

  "& button": {
    marginLeft: "0.4rem",

    "& img": {
      size: "1.3rem",
    },
  },
});
