import { selected_difficulty, ExplorerItem } from "@/models/explorer.item";

export class ExplorerItemsStorage {
  private _items: ExplorerItem[] = [];
  private static instance: ExplorerItemsStorage;

  private constructor() {}

  public static getInstance() {
    if (!ExplorerItemsStorage.instance) {
      ExplorerItemsStorage.instance = new ExplorerItemsStorage();
    }
    return ExplorerItemsStorage.instance;
  }

  get items() {
    return this._items;
  }

  public getDesktopItems(selected_difficulty: selected_difficulty, episode: string) {
    return this._items.filter(
      (item) =>
        item.parent == null &&
        item.selected_difficulty.includes(selected_difficulty) &&
        item.episode === episode
    );
  }

  public getStartMenuItems(selected_difficulty: selected_difficulty, episode: string) {
    return this._items
      .filter(
        (item) =>
          item.parent == null &&
          item.selected_difficulty.includes(selected_difficulty) &&
          item.episode === episode
      )
      .reverse();
  }

  public getItemById(id: string) {
    return this._items.find((item) => item.id === id);
  }

  public getChildrenByItemId(id: string, selected_difficulty: selected_difficulty) {
    let filtered = this._items.filter(
      (item) =>
        item.parent === id && item.selected_difficulty?.includes(selected_difficulty)
    );

    return filtered;
  }

  public getSiblingAudiosByItemId(
    id: string,
    selected_difficulty: selected_difficulty
  ) {
    const currentItem = this.getItemById(id)!;
    if (currentItem.parent == null) {
      return [];
    }

    const allSiblingAudios = this._items.filter(
      (item) =>
        item.parent === currentItem.parent &&
        item.type === "audio" &&
        item.id !== currentItem.id &&
        item.selected_difficulty?.includes(selected_difficulty)
    );

    return [currentItem, ...allSiblingAudios].map((item) => ({
      url: item.audioUrl,
      title: item.title,
    }));
  }

  public getItemPathById(id: string) {
    let currentItem = this.getItemById(id)!;

    let path: string = currentItem.title;

    while (currentItem.parent != null) {
      path = this.getItemById(currentItem.parent)!.title + "/" + path;
      currentItem = this.getItemById(currentItem.parent)!;
    }

    return "/" + path;
  }

  public addItems(...item: ExplorerItem[]) {
    this._items.push(...item);
  }
}
