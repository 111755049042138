import * as Icons from "@react95/icons";
import { styled } from "@/stitches.config";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as MaximizeIcon } from "./maximize.svg";
import { ReactComponent as MinimizeIcon } from "./minimize.svg";
import { ReactComponent as ArrowIcon } from "./arrow.svg";
import { ReactComponent as SpinnerIcon } from "./spinner.svg";

export const Computer = styled(Icons.Computer);
export const User4 = styled(Icons.User4);
export const Logo = styled(Icons.Logo);
export const Systray220 = styled(Icons.Systray220);
export const Shell3228 = styled(Icons.Shell3228);
export const Shell3216 = styled(Icons.Shell3216);
export const Shell3221 = styled(Icons.Shell3221);
export const RecycleFull = styled(Icons.RecycleFull);
export const MediaCd = styled(Icons.MediaCd);
export const Wangimg128 = styled(Icons.Wangimg128);
export const Close = styled(CloseIcon);
export const Maximize = styled(MaximizeIcon);
export const Minimize = styled(MinimizeIcon);
export const Arrow = styled(ArrowIcon);
export const Spinner = styled(SpinnerIcon);

export type StyledIconProps =
  | React.ComponentProps<typeof Computer>
  | React.ComponentProps<typeof User4>
  | React.ComponentProps<typeof Logo>
  | React.ComponentProps<typeof Systray220>
  | React.ComponentProps<typeof Shell3216>
  | React.ComponentProps<typeof Shell3221>
  | React.ComponentProps<typeof Shell3228>
  | React.ComponentProps<typeof RecycleFull>
  | React.ComponentProps<typeof MediaCd>
  | React.ComponentProps<typeof Wangimg128>
  | React.ComponentProps<typeof Close>
  | React.ComponentProps<typeof Maximize>
  | React.ComponentProps<typeof Minimize>
  | React.ComponentProps<typeof Arrow>
  | React.ComponentProps<typeof Spinner>;
