import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { Unsubscribe } from "firebase/firestore";
import { auth } from "@/firebase";
import { saveLogOnFirstLogin, setListernerForLogs } from "@/firebase/logs";
import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  fetchUser,
  selectUser,
  setUserAndLoading,
} from "@/store/slices/auth.slice";
import { resetLogs } from "@/store/slices/logs.slice";
import Login from "@/pages/login";
import Desktop from "@/pages/desktop";
import { globalStyles } from "@/stitches.config";
import { LoadingState } from "@/models/common";

function App() {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<LoadingState>("idle");

  // Fetch User
  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (userCred) => {
      setLoading("loading");
      if (userCred !== null) {
        await dispatch(fetchUser(userCred.uid));
        saveLogOnFirstLogin(userCred.uid); // must be called after fetchUser
        setLoading("succeeded");
      } else {
        dispatch(resetLogs()); // Reset Logs state to prevent showing logs of previous user
        dispatch(setUserAndLoading({ user: null, loading: "idle" }));
        setLoading("failed");
      }
    });

    return () => {
      unsub();
    };
  }, [dispatch]);

  // Set a realtime listener for logs changes
  useEffect(() => {
    let unsub: Unsubscribe;
    if (user) {
      unsub = setListernerForLogs(user.id);
    }
    return () => {
      unsub && unsub();
    };
  }, [user]);

  // Global styles
  globalStyles();

  return (
    <>
      {loading === "failed" && <Login />}
      {loading === "succeeded" && user && <Desktop />}
    </>
  );
}

export default App;
