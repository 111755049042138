import React, { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  closeWindow,
  IWindow,
  selectHighestIndex,
  toggleWindow,
} from "@/store/slices/windows.slice";
import { CloseButton } from "@/components/buttons";
import { getIconFC } from "@/components/icons";
import { Span } from "@/components/base.styled";
import { explorerItemsList } from "@/data/explorer.items.list";
import * as St from "./styles";

interface TaskbarTileProps {
  activeWindow: IWindow;
}

const TaskbarTile: React.FC<TaskbarTileProps> = ({ activeWindow }) => {
  const dispatch = useAppDispatch();
  const highestZIndex = useAppSelector(selectHighestIndex);

  const explorerItem = useMemo(() => {
    return explorerItemsList.getItemById(activeWindow.currentExplorer)!;
  }, [activeWindow.currentExplorer]);

  const Icon = useMemo(() => {
    return getIconFC(explorerItem.icon);
  }, [explorerItem.icon]);

  const closeWindowHandler = useCallback(() => {
    dispatch(closeWindow({ id: activeWindow.id }));
  }, [activeWindow.id, dispatch]);

  const toggleWindowHandler: React.MouseEventHandler<HTMLDivElement> =
    useCallback(() => {
      dispatch(toggleWindow({ id: activeWindow.id }));
    }, [activeWindow.id, dispatch]);

  return (
    <St.TileWrapper
      onClick={toggleWindowHandler}
      css={{
        backgroundColor:
          highestZIndex !== activeWindow.zIndex ? "$mono_100" : "$mono_white",
        boxShadow:
          highestZIndex !== activeWindow.zIndex ? "$win_95" : "$shadow_2",
      }}
    >
      <Icon css={{ size: "1.8rem" }} />
      <Span
        css={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          "@bp_520": {
            display: "none",
          },
        }}
      >
        {explorerItem.title}
      </Span>
      <CloseButton
        onClick={closeWindowHandler}
        css={{
          "@bp_520": {
            display: "none",
          },
        }}
      />
    </St.TileWrapper>
  );
};

export default TaskbarTile;
