import { useCallback, useMemo } from "react";
import { styled } from "@/stitches.config";
import { useAppDispatch } from "@/hooks";
import { IWindow, setCurrentExplorer } from "@/store/slices/windows.slice";
import { explorerItemsList } from "@/data/explorer.items.list";
import { Button, Div } from "@/components/base.styled";

export const IGTWrapper = styled("div", {
  fontSize: "$base",
});

export const MenuButton = styled(Button, {
  backgroundColor: "transparent",
  padding: "$sm",
  fontSize: "$sm",
});

interface IGTMenuProps {
  activeWindow: IWindow;
}

export const IGTMenu: React.FC<IGTMenuProps> = ({ activeWindow }) => {
  const dispatch = useAppDispatch();

  const explorerItem = useMemo(() => {
    return explorerItemsList.getItemById(activeWindow.currentExplorer)!;
  }, [activeWindow.currentExplorer]);

  const setCurrentExplorerHandler = useCallback(() => {
    if (explorerItem.parent != null) {
      dispatch(
        setCurrentExplorer({
          parentWindowId: activeWindow.id,
          current: explorerItem.parent,
        })
      );
    }
  }, [activeWindow.id, explorerItem.parent, dispatch]);

  return (
    <Div>
      <MenuButton onClick={setCurrentExplorerHandler}>Zurück</MenuButton>
      <MenuButton>Bearbeiten</MenuButton>
      <MenuButton>Anzeigen</MenuButton>
      <MenuButton css={{ "@bp_400": { display: "none" } }}>Hilfe</MenuButton>
    </Div>
  );
};

export const IGTContentWrapper = styled("div", {
  boxShadow: "$shadow_2",
  backgroundColor: "$mono_white",
  padding: "0.5rem",
});

export const IGTContentInner = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  alignContent: "flex-start",
  rowGap: "$sm",
  flexWrap: "wrap",
  padding: "0.5rem",
  overflowY: "auto",
  minHeight: "30rem",
  maxHeight: "60vh",
});

export const IGTInfoBox = styled("div", {
  marginTop: "0.4rem",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",

  "& > *": {
    boxShadow: "$shadow_1",
    padding: "0.6rem",
  },
});
