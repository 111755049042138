import { RootState } from "./../index";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserDoc } from "@/firebase/auth";
import { LoadingState } from "@/models/common";
import { selected_difficulty } from "@/models/explorer.item";

// Types
export interface DBUser {
  id: string;
  username: string;
  selected_difficulty: selected_difficulty;
  email_rossi_login_string: number;
  episode: string;
}

type DBUserOrNull = DBUser | null;

interface AuthState {
  user: DBUserOrNull;
  loading: LoadingState;
}

// Thunks
// Fetch User
export const fetchUser = createAsyncThunk(
  "auth/fetchUser",
  async (uid: string) => {
    return await getUserDoc(uid);
  }
);

// Initial State
const initialState: AuthState = {
  user: null,
  loading: "idle",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserAndLoading: (
      state,
      action: PayloadAction<{ user: DBUserOrNull; loading: LoadingState }>
    ) => {
      state.user = action.payload.user;
      state.loading = action.payload.loading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = "succeeded";
      })
      .addCase(fetchUser.rejected, (state) => {
        state.user = null;
        state.loading = "failed";
      });
  },
});

// Action creators
export const { setUserAndLoading } = authSlice.actions;

// Selectors
export const selectUser = (state: RootState) => state.auth.user;

// Reducer
export default authSlice.reducer;
