import uniqid from "uniqid";
import { IconsName, StyledIconProps } from "@/components/icons";

export type ExplorerPlacement = "start_menu" | "desktop" | "in_window";
export type ExplorerType =
  | "folder"
  | "kfz-register"
  | "audio"
  | "image"
  | "image.generation"
  | "pdf"
  | "no_window";
export type selected_difficulty = "easy" | "medium" | "hard";

export interface ExplorerItemType {
  title: string;
  icon: IconsName;
  type: ExplorerType;
  // will be used for accessing explorer item based on difficulty level of user
  selected_difficulty: selected_difficulty[];
  // will be used for accessing explorer item based on episode of user
  episode: string;
  audioUrl?: string; // will be used if type === "audio"
  imageUrl?: string; // will be used if type === "image"
  // id of parent ExplorerItemType
  // NOTE: existence of parent means that we should not open this item in a new window, rather in the same window
  parent?: string;
  iconProps?: StyledIconProps; // Will be used for all placements
  iconPropsDesktop?: StyledIconProps; // Will be used for desktop placement
  iconPropsStartMenu?: StyledIconProps; // Will be used for start_menu placement
  iconPropsInWindow?: StyledIconProps; // Will be used for in_window placement
  delay?: number; // Delay for visibility in minutes
  password?: string; // Password for file/folder
}

export class ExplorerItem {
  readonly id: string;
  readonly title: string;
  readonly icon: IconsName;
  readonly type: ExplorerType;
  readonly selected_difficulty: selected_difficulty[];
  readonly episode: string;
  readonly audioUrl: string | undefined;
  readonly imageUrl: string | undefined;
  readonly parent: string | undefined;
  readonly iconProps: StyledIconProps;
  readonly iconPropsDesktop: StyledIconProps;
  readonly iconPropsStartMenu: StyledIconProps;
  readonly iconPropsInWindow: StyledIconProps;
  readonly delay: number | undefined;
  readonly password: string | undefined;


  constructor(item: ExplorerItemType) {
    this.id = uniqid();
    this.title = item.title;
    this.icon = item.icon;
    this.type = item.type;
    this.selected_difficulty = item.selected_difficulty;
    this.episode = item.episode;
    this.audioUrl = item.audioUrl;
    this.imageUrl = item.imageUrl;
    this.parent = item.parent;
    this.iconProps = item.iconProps || {};
    this.iconPropsDesktop = item.iconPropsDesktop || {};
    this.iconPropsStartMenu = item.iconPropsStartMenu || {};
    this.iconPropsInWindow = item.iconPropsInWindow || {};
    this.delay = item.delay;
    this.password = item.password;

  }
}
