import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { selectUser } from "@/store/slices/auth.slice";
import { IWindow, setCurrentExplorer, closeWindow } from "@/store/slices/windows.slice";
import { selectLogs } from "@/store/slices/logs.slice";
import Explorer from "@/components/explorer";
import { explorerItemsList } from "@/data/explorer.items.list";
import * as St from "./styles";
import SyncingModal from "@/components/folder/SyncingModal";
import PasswordModal from "@/components/folder/PasswordModal";

interface FolderProps {
    activeWindow: IWindow;
}

const Folder: React.FC<FolderProps> = ({ activeWindow }) => {
    const user = useAppSelector(selectUser)!;
    const dispatch = useAppDispatch();
    const logs = useAppSelector(selectLogs);

    const folderChildren = useMemo(() => {
        return explorerItemsList.getChildrenByItemId(activeWindow.currentExplorer, user.selected_difficulty);
    }, [activeWindow.currentExplorer, user.selected_difficulty]);

    const [syncing, setSyncing] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState<string | null>(null);
    const [showPasswordModal, setShowPasswordModal] = useState(false);

    const handleCloseModal = useCallback(() => {
        setSyncing(false);
        setTimeRemaining(null);
        navigateToParentFolder();
    }, [activeWindow.id, activeWindow.currentExplorer, dispatch]);

    const navigateToParentFolder = useCallback(() => {
        const folderItem = explorerItemsList.getItemById(activeWindow.currentExplorer);
        if (folderItem && folderItem.parent != null) {
            dispatch(
                setCurrentExplorer({
                    parentWindowId: activeWindow.id,
                    current: folderItem.parent,
                })
            );
        } else {
            // If there is no parent folder, close the window
            dispatch(closeWindow({ id: activeWindow.id }));
        }
    }, [activeWindow.id, activeWindow.currentExplorer, dispatch]);

    const handlePasswordSubmit = (password: string) => {
        const folderItem = explorerItemsList.getItemById(activeWindow.currentExplorer);
        if (folderItem && folderItem.password === password) {
            setShowPasswordModal(false);
            // You can add additional logic here if needed
        } else {
            // Handle incorrect password here
        }
    };

    const handleClosePasswordModal = () => {
        setShowPasswordModal(false);
        navigateToParentFolder();
    };

    useEffect(() => {
        const folderItem = explorerItemsList.getItemById(activeWindow.currentExplorer);
        if (folderItem) {
            if (folderItem.delay && logs.first_logged_in) {
                const endTime = logs.first_logged_in + folderItem.delay * 1000;
                const currentTime = new Date().getTime();

                if (currentTime < endTime) {
                    setSyncing(true);
                    const interval = setInterval(() => {
                        const currentTime = new Date().getTime();
                        const timeDiff = endTime - currentTime;
                        if (timeDiff > 0) {
                            setTimeRemaining(Math.ceil(timeDiff / 60000) + ' minutes');
                        } else {
                            clearInterval(interval);
                            setSyncing(false);
                            setTimeRemaining(null);
                        }
                    }, 1000);
                    return () => clearInterval(interval);
                }
            }
            if (folderItem.password) {
                setShowPasswordModal(true);
            }
        }
    }, [activeWindow.currentExplorer, logs.first_logged_in]);

    return (
        <St.FolderWrapper>
            {/* Menu */}
            <St.FolderMenu activeWindow={activeWindow} />

            {/* Content */}
            {!syncing && !showPasswordModal && (
                <St.FolderContentWrapper>
                    <St.FolderContentInner>
                        {folderChildren.map((item) => (
                            <Explorer
                                key={item.id}
                                item={item}
                                placement="in_window"
                                parentWindowId={activeWindow.id}
                            />
                        ))}
                    </St.FolderContentInner>
                </St.FolderContentWrapper>
            )}

            {/* Folder Info */}
            <St.FolderInfoBox>
                <span>{folderChildren.length} Objekt(e)</span>
                <span></span>
            </St.FolderInfoBox>
            {syncing && <SyncingModal timeRemaining={timeRemaining} onClose={handleCloseModal} />}
            {showPasswordModal && <PasswordModal onClose={handleClosePasswordModal} onSubmit={handlePasswordSubmit} />}
        </St.FolderWrapper>
    );
};

export default Folder;
