import { useCallback, useMemo } from "react";
import { useAppDispatch } from "@/hooks";
import { signOutUser } from "@/firebase/auth";
import { setOpenedPathLog } from "@/firebase/logs";
import { setCurrentExplorer, openWindow } from "@/store/slices/windows.slice";
import { getIconFC, StyledIconProps } from "@/components/icons";
import { ExplorerItem, ExplorerPlacement } from "@/models/explorer.item";
import { explorerItemsList } from "@/data/explorer.items.list";
import * as St from "./styles";

interface ExplorerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  parentWindowId?: string;
  item: ExplorerItem;
  placement: ExplorerPlacement;
}

const Explorer: React.FC<ExplorerProps> = ({
  item,
  parentWindowId,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const Icon = useMemo(() => {
    return getIconFC(item.icon);
  }, [item.icon]);

  const iconProps = useMemo(() => {
    let iconProps: StyledIconProps;
    if (props.placement === "desktop") {
      iconProps = Object.assign(
        { ...item.iconProps },
        { ...item.iconPropsDesktop }
      );
    } else if (props.placement === "start_menu") {
      iconProps = Object.assign(
        { ...item.iconProps },
        { ...item.iconPropsStartMenu }
      );
    } else if (props.placement === "in_window") {
      iconProps = Object.assign(
        { ...item.iconProps },
        { ...item.iconPropsInWindow }
      );
    } else {
      iconProps = { ...item.iconProps };
    }

    return iconProps;
  }, [
    item.iconProps,
    item.iconPropsDesktop,
    item.iconPropsStartMenu,
    item.iconPropsInWindow,
    props.placement,
  ]);

  const clickHandler: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      // Logout
      if (item.title.toLowerCase() === "abmelden") {
        signOutUser();
        return;
      }
      // Don't open a window if it's a no_window
      // e.detail is 1 for single click, if it's > 1 it's a double click or more, so in that case return without opening multiple windows
      if (item.type === "no_window" || e.detail !== 1) return;

      // Log the path in db
      const path = explorerItemsList.getItemPathById(item.id);
      setOpenedPathLog(path);

      if (
        item.parent == null ||
        item.type === "audio" ||
        item.type === "image" ||
        item.type === "pdf"
      ) {
        dispatch(
          openWindow({
            rootExplorer: item.id,
          })
        );
      } else {
        if (parentWindowId) {
          dispatch(
            setCurrentExplorer({
              parentWindowId,
              current: item.id,
            })
          );
        }
      }
    },
    [dispatch, item.id, item.title, item.parent, item.type, parentWindowId]
  );

  return (
    <St.ExplorerWrapper {...props} onClick={clickHandler}>
      <Icon {...iconProps} />
      <span>{item.title}</span>
    </St.ExplorerWrapper>
  );
};

export default Explorer;
