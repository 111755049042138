import React, { useCallback, useMemo, useRef, useEffect } from "react";
import Draggable from "react-draggable";
import { MediaPlayer } from "win95-media-player-esm";
import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  closeWindow,
  IWindow,
  minimizeWindow,
  selectHighestIndex,
  setHighestZIndexForWindow,
} from "@/store/slices/windows.slice";
import { CloseButton, MinimizeButton } from "@/components/buttons";
import Folder from "@/components/folder";
import KFZRegister from "@/components/kfz.register";
import ImageGenarationTool from "@/components/image.generation";
import { getIconFC } from "@/components/icons";
import {
  TitleBar,
  TitleBarButtons,
  TitleBarText,
} from "@/components/title.bar";
import ImageViewer from "@/components/image.viewer";
import { explorerItemsList } from "@/data/explorer.items.list";
import * as St from "./styles";
import { downloadFile } from "../pdf/download.trigger";

interface SingleWindowProps {
  activeWindow: IWindow;
}

const SingleWindow: React.FC<SingleWindowProps> = ({ activeWindow }) => {
  const dispatch = useAppDispatch();
  const nodeRef = useRef<HTMLDivElement>(null);
  const highestZIndex = useAppSelector(selectHighestIndex);

    const explorerItem = useMemo(() => {
        return explorerItemsList.getItemById(activeWindow.currentExplorer)!;
    }, [activeWindow.currentExplorer]);

    const Icon = useMemo(() => {
        return getIconFC(explorerItem.icon);
    }, [explorerItem.icon]);

    const closeWindowHandler = useCallback(() => {
        dispatch(closeWindow({ id: activeWindow.id }));
    }, [activeWindow.id, dispatch]);

    const bringInFocus = useCallback(() => {
        if (activeWindow.zIndex < highestZIndex) {
            dispatch(setHighestZIndexForWindow({ id: activeWindow.id }));
        }
    }, [activeWindow.id, activeWindow.zIndex, dispatch, highestZIndex]);

    const minimizeWindowHandler = useCallback(() => {
        dispatch(minimizeWindow({ id: activeWindow.id }));
    }, [activeWindow.id, dispatch]);

    useEffect(() => {
        if (explorerItem.type === "pdf") {
            downloadFile(explorerItem.imageUrl, explorerItem.title + '.pdf');
            closeWindowHandler();
        }
    }, [explorerItem, closeWindowHandler]);

  // For Audio Media Player
  if (explorerItem.type === "audio") {
    return (
      <Draggable handle=".drag-handle" nodeRef={nodeRef}>
        <St.AudioWindowWrapper
          css={{
            zIndex: activeWindow.zIndex,
            display: activeWindow.isMinimized ? "none" : "block",
            left: activeWindow.left,
            top: activeWindow.top,

            "@bp_520": {
              left: "5vw",
              top: activeWindow.top - 30,
            },
          }}
          ref={nodeRef}
        >
          <div onMouseDown={bringInFocus} onTouchStart={bringInFocus}>
            <MediaPlayer
              className={`${St.mediaPlayerClass()}`}
              dragHandlerClassName={`drag-handle ${St.mediaPlayerHeaderClass()}`}
              playlist={[
                { title: explorerItem.title, url: explorerItem.audioUrl },
              ]}
              fullscreenEnabled
              closeClickHandler={closeWindowHandler}
              minimizeClickHandler={minimizeWindowHandler}
            />
          </div>
        </St.AudioWindowWrapper>
      </Draggable>
    );
  }

  return (
    <Draggable handle=".drag-handle" nodeRef={nodeRef}>
      <St.SingleWindowWrapper
        css={{
          zIndex: activeWindow.zIndex,
          display: activeWindow.isMinimized ? "none" : "block",
          left: activeWindow.left,
          top: activeWindow.top,

          "@bp_520": {
            left: "5vw",
            top: activeWindow.top - 30,
          },
        }}
        ref={nodeRef}
      >
        <div onMouseDown={bringInFocus} onTouchStart={bringInFocus}>
          <TitleBar
            className="drag-handle"
            css={{
              backgroundColor:
                highestZIndex !== activeWindow.zIndex ? "$mono_200" : "",
            }}
          >
            <Icon css={{ size: "1.8rem", marginRight: "0.5rem" }} />
            <TitleBarText
              color={highestZIndex !== activeWindow.zIndex ? "black" : "white"}
            >
              {explorerItem.title}
            </TitleBarText>
            <TitleBarButtons>
              <MinimizeButton
                onClick={minimizeWindowHandler}
                onTouchStart={minimizeWindowHandler}
              />
              <CloseButton
                onClick={closeWindowHandler}
                onTouchStart={closeWindowHandler}
              />
            </TitleBarButtons>
          </TitleBar>
          {explorerItem.type === "folder" && (
            <Folder activeWindow={activeWindow} />
          )}
          {explorerItem.type === "kfz-register" && <KFZRegister />}
          {explorerItem.type === "image" && (
            <ImageViewer explorerItem={explorerItem} />
          )}
          {explorerItem.type === "image.generation" && (
            <ImageGenarationTool activeWindow={activeWindow} />
          )}
        </div>
      </St.SingleWindowWrapper>
    </Draggable>
  );
};

export default SingleWindow;
