import { styled } from "@/stitches.config";

export const DesktopWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
});

export const DesktopItemsWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  flexWrap: "wrap",
  height: "100%",
  alignContent: "start",
  position: "relative",
  overflow: "hidden",
});
